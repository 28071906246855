import lodash from 'lodash';
import Vue from 'vue';

import '../../registerServiceWorker';

/**
 *  Import Services
 */
import ApiService from '../../configs/services/api/ApiService';
import AppConfigs from '../../configs/AppConfigs';
import AuthService from '../../configs/services/auth/AuthService';
import LangService from '../../configs/services/lang/LangService';

import '../../preloader';

/**
 *  Components import.
 */
import ErrorPage from '../../components/errors/404.vue';

/**
 * Styles import.
 */
import '../../sass/app.scss';
import Buttons from '../../components/helpers/Buttons';

Vue.component('v-button', Buttons);
/**
 * Set app services
 */
Vue.use(ApiService);
Vue.use(AuthService);
Vue.use(LangService);
Vue.use(AppConfigs);

Vue.use(lodash);

Vue.config.productionTip = false;
window.Vue = require('vue');

new Vue({
  el: root_element,
  props: {
    propModel: String,
  },
  propsData: {...root_element.dataset},
  render(h) {
    return h(ErrorPage);
  },
});
