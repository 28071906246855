<template>
    <error-template :top-text="topText" :error-code="errorCode" :description="description" />
</template>

<script>
  import '../../sass/pages/errors/404.scss';
  import ErrorTemplate from './ErrorTemplate.vue';

export default {
  name: 'Error404',
  components: {
    'error-template': ErrorTemplate,
  },
  data() {
    return {
      topText: 'Простите, страница не найдена',
      errorCode: '404',
      description: 'Сраница, которую Вы ищите, сейчас не доступна, изменено название или удалена.',
    };
  },
};
</script>
