<template>
    <div class="error--container">
        <div class="error__element error__element--top-text">
            <div class="error__text error__text--top-text">
                {{ topText }}
            </div>
        </div>
        <div class="error__element error__element--error-code">
            <div class="error__text--error-code">
                {{ errorCode }}
            </div>
        </div>
        <div class="error__element error__element--bottom-text">
            <div class="error__text error__text--description">
                {{ description }}
            </div>
        </div>
        <div class="error__element error__element--buttons">
            <v-button
                text="Назад" width="174"
                type="green"
                @button-click="goBack"
            />
        </div>
    </div>
</template>

<script>
  import '../../sass/pages/errors/404.scss';

export default {
  name: 'ErrorTemplate',
  props: {
    topText: String,
    errorCode: String,
    description: String,
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>
